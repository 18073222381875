import React from "react";
import Post from "../../components/post";

export default function Post3() {
  return (
    <Post i={2}>
      <p>
        I'm not sure when this happened, but it has just dawned on me that I'm
        an “experienced” developer. I mean, I still don't really know what I'm
        doing, but enough years have gone by that I can pretend like I do (with
        confidence!). I went to EmberConf this year, and the event that stuck
        out to me was a mentorship gathering that brought together people new to
        the industry (or new to Ember.js) and those that were more experienced
        with JavaScript, Ember, tech conferences, and other open-source related
        things.
      </p>
      <p>
        There was a speed-date style rotation of mentors with mentees, and I got
        to meet and chat with all the mentees for 3 (ish) minutes. It was this
        experience that I walked away with a ton of insight into the brain of
        those just getting into the industry, and I realized I need to be more
        prepared to help those that are just starting out. Most questions I
        don't remember and plenty left me speechless, but I'll summarize some of
        the realizations I walked away with:
      </p>
      <ul>
        <li>
          I don't think engineers do enough to make people with less experience
          or less subject matter knowledge comfortable. It makes me sad that
          people entering the field are terrified because… wait for it… they
          have no experience. For every person that has the most experience,
          they have a story of how they started.{" "}
          <b>
            We, the “experienced” people, need to be better at communicating
            that zero-knowledge on Day 1 is not just tolerated, but it's
            welcomed.
          </b>
        </li>
        <li>
          Do people think that 1:1's with their managers are just to gab? I
          don't think there is a “Beginners guide to get the best out of your
          first job” book out there anywhere (although, there should be!).{" "}
          <b>New developers need to be told how to utilize their managers.</b>{" "}
          I'm not a manager, but I have an agenda when it comes to 1:1's because
          I want to a) use my manager's precious time wisely and b) ensure they
          have the information to help me succeed.
        </li>
        <li>Ditto to 1:1's for peer feedback.</li>
        <li>
          I was astonished at how many new developers viewed any sort of
          SCRUM/Kanban/Sprint deadlines/whatever as a be-all-end-all on their
          time and work. There was not a great understanding of the iterative
          nature and benefits of such workflows. New developers may not ask why
          certain processes are the way they are because they are so overwhelmed
          with all the technical topics they need to learn as well.{" "}
          <b>
            It's our jobs to make sure the value of chosen processes is clear so
            others find it valuable too (or challenge it).
          </b>
        </li>
        <li>
          On the topic of questions and being overwhelmed, it's our jobs to
          anticipate what a new developer needs. Some will be more vocal than
          others about needing help.{" "}
          <b>
            Offering unsolicited guidance may seem annoying to the receiver, but
            try to put yourself in their shoes.
          </b>{" "}
          If you were brand new to a field (and shy), how much easier would it
          be for someone to offer their expertise without having to ask?
        </li>
      </ul>
      <p>
        There were so many other great questions from the mentees that I can't
        begin to summarize here — it was refreshing to get perspectives from
        those new to the field. Anyways, that's all I've got. ✌️
      </p>
    </Post>
  );
}
